import * as appConstant from "../constant/appConstant";

export const PortfolioInfo = [
  {
    id: appConstant.AVITECH,
    title: "AviTech Software Solutions",
    content: `
            AviTech is a leading provider of data-to-cash solutions for businesses in the public and private sectors. Our innovative software solutions revolutionize data processing and payment automation, solving challenges and ensuring revenue assurance.<br/><br/>
With a specialized focus on data collection and payment automation, AviTech has built an impressive track record in Nigeria and is expanding into emerging markets across Africa. Our solutions optimize data capture and processing, streamline billing and invoicing, and automate passenger and flight information for enhanced transparency and accuracy. <br/><br/>
Having processed over $136M in transactions across 22 Nigerian airports, AviTech delivers end-to-end data management, payment, and revenue optimization solutions.<br/><br/>
Join us in transforming your data into tangible results. Explore AviTech's comprehensive solutions today.
        `,
    logo: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/logo/avitech_logo_colored 1.png",
    href: "https://www.avitechng.com/",
  },
  {
    id: appConstant.TRIPS,
    title: "Trips",
    content: `
            Trips is revolutionizing the travel industry space by creating new ways for customers to book their tickets.<br/><br/>
With experience and expertise in Aviation and Transportation Technology, Trips is leveraging technology to solve problems across multiple sectors of the transport value chain focusing on retail and ticketing solutions, asset financing and analytics. <br/><br/>
Having amassed years of experience and expertise in the Aviation industry, we have carefully observed how the absence of Domestic Global Distribution System (GDS) for Domestic Airlines has hampered their growth and scale of Airlines and domestic travel traffic. While an International GDS exists, domestic Airlines across Africa have found it difficult and expensive to exist on the platform.<br/><br/>
To address this gap, we have developed a single API that aggregates airlines inventory aimed at providing a one-stop-shop for seamless ticketing and payment settlement.<br/><br/>
This single-window platform will not only aggregates airlines inventories but also inventories of other modes of transport to facilitates ticket sales through omni channels (Mobile Apps, ATMs, USSD, Telcos, FinTechs platforms etc.).<br/><br/>
Join the ride as we revolutionize travel as we know it. Explore Trips.
        `,
    logo: "https://avitechvideos.blob.core.windows.net/tranzitech-website-assets/logo/trips.png",
    href: "https://www.trips.ng/",
  },
];
