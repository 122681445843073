import { FC } from "react";

import blogImage from "../../../../assets/images/blog-card-image1.svg";

interface BlogCardProps {
  title: string;
  blogImg: string;
  date: string;
}

const FirstBlogCard: FC<BlogCardProps> = ({
  blogImg = blogImage,
  title,
  date,
}) => {
  return (
    <div className="rounded-[15px] relative h-[375px] overflow-hidden ">
      <div className=" rounded-[15px]  h-[170px] ">
        <img
          src={blogImage}
          alt={title}
          className="w-screen cover flex-1 h-auto transition-transform duration-300 transform hover:scale-110 rounded-[25px] lg:rounded-[15px]"
        />
      </div>
      <div className="bg-purple-900 text-white px-[24px] py-[32px]  lg:py-[32px] font-RobotoBold absolute bottom-0 w-[100%] rounded-br-[15px] rounded-bl-[15px]">
        <h2>{title}</h2>
        <div className="lg:pt-[28px]">
          <small>{date}</small>
        </div>
      </div>
    </div>
  );
};

export default FirstBlogCard;
